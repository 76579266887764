<template>
  <div>
    <a-card style="margin-bottom: 20px">
      <a-row type="flex" :gutter="[16, 16]">
        <a-col :lg="6" :md="12" :xs="24" class="option-wrapper">
          <span>搜索：</span>
          <a-input-search
            v-model="query.name"
            allowClear
            placeholder="请输入"
            style="flex: 1"
            @search="initData"
          />
        </a-col>
        <a-col :lg="6" :md="12" :xs="24" class="option-wrapper">
          <span style="white-space: nowrap">创建时间：</span>
          <a-range-picker v-model="dateRange" @change="initData">
            <a-icon slot="suffixIcon" type="calendar" />
          </a-range-picker>
        </a-col>
        <a-col :lg="6" :md="12" :xs="24" class="option-wrapper">
          <span>服务机构：</span>
          <a-input-search
            v-model="query.mechanismName"
            allowClear
            placeholder="请输入机构名称"
            style="flex: 1"
            @search="initData"
          />
        </a-col>
      </a-row>
    </a-card>

    <a-card title="服务列表" v-if="$auth('home_school_student_list')" :body-style="{ padding: 0 }">
      <div slot="extra">
        <a-button
          v-if="$auth('export_teaching_data')"
          type="primary"
          shape="round"
          @click="handleLearnData"
        >
          <a-icon type="plus" />导出教学数据
        </a-button>
      </div>
      <service-table ref="table"></service-table>
    </a-card>

    <learn-data-export ref="learnRef"></learn-data-export>
  </div>
</template>

<script>
import ServiceTable from './components/Table'
import learnDataExport from './components/learn-data-export'
export default {
  name: 'HomeSchoolList',
  components: {
    ServiceTable,
    learnDataExport,
  },
  data() {
    return {
      query: {
        name: '',
        mechanismName: undefined,
      },
      dateRange: [],
    }
  },
  mounted() {
    const { $nextTick, initData } = this

    $nextTick(() => {
      this.ready = true
    })

    initData()
  },
  methods: {
    initData() {
      const { dateRange, query } = this
      const [startTime, endTime] = [
        dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : '',
        dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : '',
      ]
      this.$refs.table.initData({ ...query, startTime, endTime })
    },
    // 重新评估审核
    handleReEvaluationReview() {
      this.$router.push({
        name: 'homeSchoolEvaluationReview',
      })
    },
    // 导出教学数据
    handleLearnData() {
      this.$refs.learnRef.show()
    },
  },
}
</script>

<style lang="less" scoped>
.option-wrapper {
  display: flex;
  align-items: center;
}
</style>
