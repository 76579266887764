<!--去上课(家校服务-上课、家校服务-详情-上课记录)-->
<template>
  <div>
    <a-modal
      v-model="visible"
      title="教学数据导出"
      width="424px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round', loading: buttonLoading },
        domProps: {
          innerHTML: '导出',
        },
      }"
      :cancel-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '取消',
        },
      }"
      @ok="exportInfo"
      @cancel="visible = false"
    >
      <a-form :model="form" layout="inline">
        <a-form-item label="导出机构：">
          <a-cascader
            :options="options"
            :field-names="{ label: 'name', value: 'id', children: 'childrenList' }"
            expand-trigger="hover"
            placeholder="导出机构"
            v-model="defaultValue"
            @change="onOrgChange"
            :allowClear="false"
          />
        </a-form-item>
        <a-form-item label="导出月份：">
          <a-month-picker
            :disabledDate="disabledDate"
            v-model="dateMonth"
            :format="monthFormat"
            @change="onDateChange"
            :allowClear="false"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import * as mechanismApi from '@/api/mechanism'
import * as planApi from '@/api/plan'
import moment from 'moment'

export default {
  name: 'LearnDataExport',
  data() {
    return {
      visible: false,
      isDatepicker: false,
      buttonLoading: false,
      query: {
        pageIndex: 1,
        pageSize: 10,
      },
      form: {},
      orgName: '',
      options: [],
      defaultValue: [],
      dateMonth: '',
      monthFormat: 'YYYY-MM',
    }
  },
  methods: {
    disabledDate(time) {
      return new Date(time).getTime() < new Date('2023-03').getTime()
    },
    onOrgChange(val, selectedOptions) {
      this.defaultValue = val
      this.form.orgId = selectedOptions[val.length - 1].id
      this.orgName = selectedOptions[val.length - 1].name
    },
    onDateChange(val) {
      const endDate = moment(val._d).format('YYYY-MM')
      this.disabledDate(endDate)
      this.form.endDate = endDate
    },
    exportInfo() {
      if (!this.form.orgId) {
        this.$notification['error']({ message: '请选择导出机构' })
        return
      }
      if (!this.form.endDate) {
        this.$notification['error']({ message: '请选择导出月份' })
        return
      }
      this.buttonLoading = true
      planApi
        .exportPlanInfo(this.form)
        .then((res) => {
          const link = document.createElement('a')
          const blob = new Blob([res], { type: 'multipary/form-data' })
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.setAttribute('download', decodeURI(`${this.form.endDate}${this.orgName}教学数据.xlsx`))
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.visible = false
          this.buttonLoading = false
        })
        .catch(() => {
          this.$notification['error']({ message: '导出失败' })
          this.buttonLoading = false
        })
    },
    getMechanismList() {
      mechanismApi.mechanismOptions().then((res) => {
        this.options = this.getTreeData(res.data)
      })
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].childrenList && data[i].childrenList.length < 1) {
          data[i].childrenList = undefined
        } else if (data[i].childrenList) {
          this.getTreeData(data[i].childrenList)
        }
      }
      return data
    },
    show() {
      this.visible = true
      this.options = []
      this.defaultValue = []
      this.orgName = ''
      this.dateMonth = ''
      this.form = {}
      this.getMechanismList()
    },
  },
}
</script>

<style scoped></style>
