<!--去上课(家校服务-上课、家校服务-详情-上课记录)-->
<template>
  <div>
    <a-modal
      v-model="visible"
      title="信息提示"
      width="424px"
      height="240px"
      :body-style="{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' }"
      :maskClosable="false"
      :closable="false"
      :ok-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '继续上课',
        },
      }"
      :cancel-button-props="{
        props: { shape: 'round' },
        domProps: {
          innerHTML: '重新开始',
        },
      }"
      @ok="continueHistoryClass"
      @cancel="beginNewClass"
    >
      <div class="modaltitle">您于【{{ classDate }}】开始的课程还未结束，是否继续该课程教学?</div>
    </a-modal>
  </div>
</template>

<script>
import * as studentApi from '@/api/student'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'AttendClass',
  data() {
    return {
      visible: false, // 是否有未结束课程-弹窗
      isUnfinished: false, // 是否有未结束课程
      classDate: '',
      nextQuery: {} //  下一个页面的参数
    }
  },
  methods: {
    ...mapActions(['changeSpinning']),

    // 继续上课
    continueHistoryClass() {
      const { nextQuery, isUnfinished } = this

      const query = {
        ...nextQuery,
        ...{ isUnfinished }
      }
      this.$router.push({
        name: 'homeSchoolClassesBegin',
        query
      })
    },
    // 重新上课
    beginNewClass() {
      const { nextQuery, isUnfinished } = this
      const query = { ...nextQuery, ...{ isUnfinished } }
      this.$router.push({
        name: 'homeSchoolGoToClass',
        query
      })
    },
    // 上课
    handleGoToClass({ id: studentId, teacher, username, sex }) {
      this.changeSpinning(true)
      const params = { studentId }
      this.nextQuery = { studentId, teacher, username, sex }
      studentApi
        .isUnfinishedClass(params)
        .then(res => {
          const { code, data } = res
          if (code === 0) {
            const { classDate, isHasUnfinishedClass } = data
            if (isHasUnfinishedClass) {
              this.visible = true
              this.isUnfinished = isHasUnfinishedClass
              this.classDate = moment(classDate).format('YYYY年MM月DD日HH时mm分SS秒')
            } else {
              this.$router.push({
                name: 'homeSchoolGoToClass',
                query: this.nextQuery
              })
            }
          }
        })
        .finally(() => {
          this.changeSpinning(false)
        })
    }
  }
}
</script>

<style scoped></style>
