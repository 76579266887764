import request from '@/utils/request'
const mechanismApi = {
  list: '/mechanism',
  create: '/mechanism',
  update: id => `/mechanism/${id}`,
  detail: id => `/mechanism/${id}`,
  remove: id => `/mechanism/${id}`,
  mechanismOptions: '/mechanism/all'
}
export function list(parameter) {
  return request({
    url: mechanismApi.list,
    method: 'get',
    params: parameter
  })
}
export function create(parameter) {
  return request({
    url: mechanismApi.create,
    method: 'post',
    data: parameter
  })
}
export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: mechanismApi.update(id),
    method: 'put',
    data: parameter
  })
}
export function detail(parameter) {
  return request({
    url: mechanismApi.detail(parameter),
    method: 'get'
  })
}
export function remove(parameter) {
  return request({
    url: mechanismApi.remove(parameter),
    method: 'delete'
  })
}
export function mechanismOptions() {
  return request({
    url: mechanismApi.mechanismOptions,
    method: 'get'
  })
}
