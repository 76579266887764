<template>
  <div>
    <a-table rowKey="id" :data-source="list" :pagination="pagination" @change="handleTableChange">
      <a-table-column key="username" title="学员" data-index="username" align="center" :ellipsis="true" width="10%" />

      <a-table-column key="sex" title="性别" data-index="sex" align="center" :ellipsis="true" width="8%">
        <template slot-scope="sex">
          <span>
            {{ { MALE: '男', FEMALE: '女' }[sex] }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="age" title="年龄" data-index="age" align="center" :ellipsis="true" width="8%" />
      <a-table-column
        key="cellphone"
        title="联系电话"
        data-index="cellphone"
        align="center"
        :ellipsis="true"
        width="10%"
      />
      <a-table-column
        key="homeSchoolOpeningTime"
        title="开通时间"
        data-index="homeSchoolOpeningTime"
        align="center"
        :ellipsis="true"
        width="12%"
      >
        <template slot-scope="homeSchoolOpeningTime">
          <span>
            {{ homeSchoolOpeningTime | moment('YYYY-MM-DD') }}
          </span>
        </template>
      </a-table-column>
      <a-table-column
        key="mechanismName"
        title="服务机构"
        data-index="mechanismName"
        align="center"
        :ellipsis="true"
        width="10%"
      />

      <a-table-column
        key="planWriter"
        title="计划撰写师"
        data-index="planWriter"
        align="center"
        :ellipsis="true"
        width="10%"
      >
        <template slot-scope="planWriter">
          <span>
            {{ planWriter || '--' }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="teacher" title="授课教师" data-index="teacher" align="center" :ellipsis="true" width="10%">
        <template slot-scope="teacher">
          <span>
            {{ teacher || '--' }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="17%">
        <template slot-scope="record">
          <a-space>
            <a-button type="link" @click="handleDetail(record)" v-if="isDeatil"> <a-icon type="right-circle" />详情</a-button>
            <a-button
              v-if="$auth('teaching_class_record') && record.isTeacher && record.schoolStatus === 'ADMISSION'"
              type="link"
              size="small"
              @click="handleGoToClass(record)"
            >
              <a-icon type="profile" />上课</a-button
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>
    <!--去上课-->
    <attend-class ref="attendClass"></attend-class>
  </div>
</template>

<script>
import * as studentApi from '@/api/student'
import { mapActions } from 'vuex'
import AttendClass from '@/views/home-school-service/components/attend-class'
export default {
  components: {
    AttendClass,
  },
  data() {
    return {
      visible: false, // 是否有未结束课程-弹窗
      isUnfinished: false, // 是否有未结束课程
      query: {},
      pagination: {},
      list: [],
    }
  },
  activated() {
    const { ready, getData } = this

    if (ready) {
      getData()
    }
  },
  computed: {
    isDeatil(){
      return this.$auth(['home_school_student_base_info', 'skills_assessment', 'training_plan', 'teaching_class', 'plan_work', 'data_statistics', 'data_report'])
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    ...mapActions(['changeSpinning']),
    // 继续上课
    continueHistoryClass() {
      const { nextQuery, isUnfinished } = this

      const query = {
        ...nextQuery,
        ...{ isUnfinished },
      }
      this.$router.push({
        name: 'homeSchoolClassesBegin',
        query,
      })
    },
    // 重新上课
    beginNewClass() {
      const { nextQuery, isUnfinished } = this
      const query = { ...nextQuery, ...{ isUnfinished } }
      this.$router.push({
        name: 'homeSchoolGoToClass',
        query,
      })
    },

    // 上课
    handleGoToClass(record) {
      this.$refs.attendClass.handleGoToClass(record)
    },
    initData(query = {}) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query,
      } = this

      this.changeSpinning(true)
      return studentApi
        .stList({
          ...query,
          pageIndex,
          pageSize,
        })
        .then((res) => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.changeSpinning(false)
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: (total) => {
          const h = this.$createElement
          const {
            pagination: { pageSize },
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h(
              'span',
              {
                class: 'primary-color',
              },
              pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)
            ),
            h('span', ' 页'),
            h('span', ' / '),
            h(
              'span',
              {
                class: 'primary-color',
              },
              total
            ),
            h('span', ' 条数据'),
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },

    // 详情
    handleDetail(record) {
      const { id } = record
      this.$router.push({
        name: 'homeSchoolDetail',
        params: {
          id,
        },
        query: {
          row: JSON.stringify(record), // 将对象转成json字符串
          // row: JSON.stringify({ ...record, ...{ schoolStatus: 'WITHDRAWAL' } }) // 将对象转成json字符串
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}

/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}

@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
