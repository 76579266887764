var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"title":"信息提示","width":"424px","height":"240px","body-style":{ paddingTop: '18px', paddingLeft: '24px', paddingBottom: '18px', paddingRight: '24px' },"maskClosable":false,"closable":false,"ok-button-props":{
      props: { shape: 'round' },
      domProps: {
        innerHTML: '继续上课',
      },
    },"cancel-button-props":{
      props: { shape: 'round' },
      domProps: {
        innerHTML: '重新开始',
      },
    }},on:{"ok":_vm.continueHistoryClass,"cancel":_vm.beginNewClass},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"modaltitle"},[_vm._v("您于【"+_vm._s(_vm.classDate)+"】开始的课程还未结束，是否继续该课程教学?")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }