import request from '@/utils/request'

const planApi = {
  list: '/plans',
  create: id => `/homeschool-plans/${id}`,
  downgradeSkill: id => `/plans/downgrade/${id}`,
  upgradableSkill: id => `/plans/upgradable/${id}`,
  upgradeSkill: id => `/planSkill/${id}/upgrade`,
  removeSkill: id => `/plans/delete/${id}`,
  skipSkill: id => `/planSkill/${id}/skip`,
  publishSkill: id => `/plans/status/${id}`,
  replaceSkill: ({ planId, skillId }) => `/plans/replace/${planId}/${skillId}`,
  createSkill: ({ studentId, skillId }) => `/plans/skills/${studentId}/${skillId}`,
  updateSkill: id => `/plans/${id}`,
  phaseDetail: id => `/plans/phase/${id}`,
  updatePhase: id => `/plans/phase/${id}`,
  getLastAnswerQuestionDetail: ({ studentId, subjectId }) =>
    `evaluations/last/students/${studentId}/subjects/${subjectId}`,
  isQualified: (studentId, planPhaseId) => `/plans/isUpgrade/students/${studentId}/plan-phases/${planPhaseId}`,
  fieldList: studentId => `student/field/${studentId}`, // 评估领域列表
  allSubject: evaluationFieldId => `/subjects/evaluationField/${evaluationFieldId}`,
  answer: '/evaluations',
  submit: evaluationFieldId => `/evaluations/fields/${evaluationFieldId}`,
  reassessments: studentId => `student/${studentId}/reassessments`, // 该学员是否可以重新评估
  reassessmentsAll: studentId => `evaluations/reassessments/${studentId}/reassessments`, // 重新评估-全部
  fieldScore: evaluationFieldId => `evaluations/fieldScore/${evaluationFieldId}`, // 评估领域列表-查看报告
  reassessmentsSingle: evaluationFieldId => `evaluations/reset/${evaluationFieldId}`, // 重新评估-单个题目
  studentPhaseDetail: ({ phaseId, studentId }) => `/plans/student/phase/${phaseId}?studentId=${studentId}`, // 查询学生阶段详情
  deletePlanPhaseMaterial: planPhaseMaterialId => `/planSkill/material/${planPhaseMaterialId}`, // 删除-计划阶段素材
  editPlanPhaseMaterial: planPhaseMaterialId => `/planSkill/material/${planPhaseMaterialId}`, // 编辑-计划阶段素材
  exportPlanInfo: `/plans/export/plan-skill/data`, // 导出达标数据
}

// 编辑-计划阶段素材
export function editPlanPhaseMaterial(parameter) {
  const { planPhaseMaterialId } = parameter
  delete parameter.planPhaseMaterialId
  return request({
    url: planApi.editPlanPhaseMaterial(planPhaseMaterialId),
    method: 'patch',
    data: parameter
  })
}

// 删除计划阶段素材
export function deletePlanPhaseMaterial(parameter) {
  return request({
    url: planApi.deletePlanPhaseMaterial(parameter),
    method: 'delete'
  })
}

// 查询学生阶段详情
export function getStudentPhaseDetail(parameter) {
  return request({
    url: planApi.studentPhaseDetail(parameter),
    method: 'get'
  })
}

// 重新评估-单个题目
export function saveReassessmentsSingle(parameter) {
  const { evaluationFieldId } = parameter
  return request({
    url: planApi.reassessmentsSingle(evaluationFieldId),
    method: 'put'
  })
}

// 评估领域列表-查看报告
export function getFieldScore(parameter) {
  const { evaluationFieldId } = parameter
  return request({
    url: planApi.fieldScore(evaluationFieldId),
    method: 'get'
  })
}

// 重新评估-全部
export function saveReassessmentsAll(parameter) {
  const { studentId } = parameter
  return request({
    url: planApi.reassessmentsAll(studentId),
    method: 'post'
  })
}

// 该学员是否可以重新评估
export function reassessments(parameter) {
  const { studentId } = parameter
  return request({
    url: planApi.reassessments(studentId),
    method: 'get'
  })
}
// 点击下一题时调用答题
export function answer(parameter) {
  return request({
    url: planApi.answer,
    method: 'post',
    data: parameter
  })
}
// 提交
export function evaluationSubmit(parameter) {
  return request({
    url: planApi.submit(parameter),
    method: 'put'
  })
}

// 查询此领域-所有题目(已做过、未做过)
export function searchAllSubject(parameter) {
  return request({
    url: planApi.allSubject(parameter),
    method: 'get'
  })
}

// 评估领域列表
export function getFieldListByStudent(parameter) {
  const { studentId } = parameter
  return request({
    url: planApi.fieldList(studentId),
    method: 'get'
  })
}

// 根据学生id,题目id查询最后一次评估该题目的答题情况
export function getLastAnswerQuestionDetail(parameter) {
  return request({
    url: planApi.getLastAnswerQuestionDetail(parameter),
    method: 'get'
  })
}

export function list(parameter) {
  return request({
    url: planApi.list,
    method: 'get',
    params: parameter
  })
}

export function create(parameter) {
  return request({
    url: planApi.create(parameter),
    method: 'post'
  })
}

export function downgradeSkill(parameter) {
  return request({
    url: planApi.downgradeSkill(parameter),
    method: 'patch'
  })
}

export function upgradableSkill(parameter) {
  return request({
    url: planApi.upgradableSkill(parameter),
    method: 'get'
  })
}

export function upgradeSkill(parameter) {
  return request({
    url: planApi.upgradeSkill(parameter),
    method: 'patch'
  })
}

export function skipSkill(parameter) {
  return request({
    url: planApi.skipSkill(parameter),
    method: 'patch'
  })
}

export function publishSkill(parameter) {
  return request({
    url: planApi.publishSkill(parameter),
    method: 'patch'
  })
}

export function removeSkill(parameter) {
  return request({
    url: planApi.removeSkill(parameter),
    method: 'delete'
  })
}

export function replaceSkill(parameter) {
  return request({
    url: planApi.replaceSkill(parameter),
    method: 'patch'
  })
}

export function createSkill(parameter) {
  return request({
    url: planApi.createSkill(parameter),
    method: 'post'
  })
}

export function updateSkill(parameter) {
  const { id } = parameter
  delete parameter.id

  return request({
    url: planApi.updateSkill(id),
    method: 'patch',
    data: parameter
  })
}

export function phaseDetail(parameter) {
  return request({
    url: planApi.phaseDetail(parameter),
    method: 'get'
  })
}

export function updatePhase(parameter) {
  const { id } = parameter
  delete parameter.id

  return request({
    url: planApi.updatePhase(id),
    method: 'patch',
    data: parameter
  })
}

// 阶段是否达标
export function isQualified(parameter) {
  const { studentId, planPhaseId } = parameter
  return request({
    url: planApi.isQualified(studentId, planPhaseId),
    method: 'get'
  })
}

export function exportPlanInfo(parameter) {
  return request({
    url: planApi.exportPlanInfo,
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}